<template>
  <section class="spinning-stages" v-if="stages.length">
    <div class="stages-content">
      <div class="section-title-contaner mb-3">
        <h2 class="section-title right">{{ title }}</h2>
      </div>
      <div class="stages-content-main overflow-hidden">
        <b-row>
          <b-col
            cols="12"
            v-for="(stage, index) in stages"
            :key="index"
            class="parent-stage-container"
          >
            <div class="stage">
              <img
                class="spinning-image-bg"
                :src="
                  stage.imageDto
                    ? stage.imageDto.path
                      ? stage.imageDto.path.replace('~', $store.getters['app/domainHost'])
                      : ''
                    : ''
                "
                :data-aos="'fade-' + (index % 2 == 0 ? 'right' : 'left')"
                data-aos-duration="2000"
              />
              <div
                class="stage-container px-md-5"
                :data-aos="'fade-' + (index % 2 == 0 ? 'right' : 'left')"
                data-aos-duration="3500"
              >
                <b-col
                  cols="6"
                  :data-aos="'fade-' + (index % 2 == 0 ? 'right' : 'left')"
                  data-aos-duration="4500"
                >
                  <div v-html="stage.description"></div>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(["stages"]),
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables/variables.scss";
.spinning-stages {
  overflow: hidden;
  .stages-content {
    .stages-content-title {
      h2 {
        padding: 0 4rem;
        font-weight: bold;
        margin: 0;
        line-height: 1.5;
        color: $primary;
      }
    }
    .stages-content-main {
      .stage {
        position: relative;
        .spinning-image-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }
        .stage-container {
          min-height: 340px;
          display: flex;
          align-items: center;
          height: 100%;
          font-weight: 600;
          color: #fff;
          background: linear-gradient(to left, transparent, $primary, $primary);
        }
      }
      .parent-stage-container {
        &:nth-of-type(2n-1) {
          .stage-container {
            flex-direction: row-reverse;
            background: linear-gradient(
              to left,
              $secondaryPrimary,
              $secondaryPrimary,
              transparent
            );
          }
        }
      }
    }
  }
  ::v-deep {
    * {
      color: #fff!important;
    }
  }
}
</style>
