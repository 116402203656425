<template>
  <div>
    <mainSectionVideo />
    <spinningStages :title="stagesTitle.title"/>
    <section class="our-services pt-5">
      <div class="section-title-contaner mb-3">
        <h2 class="section-title right">{{t('nav').services}}</h2>
      </div>
      <div class="services-container">
        <b-col cols="12">
          <b-row class="mb-3 services-container-item-row" v-for="(service, index) in services" :key="index">
            <b-col cols="12" lg="5" :order-lg="index%2 != 0 ? '1' : ''" data-aos="fade-up" data-aos-duration="5000">
              <div class="service-image-container mx-auto mb-3">
                <img :src="service.imageDto.path.replace('~', $store.getters['app/domainHost'])" />
                <p>{{service.order}}</p>
                <span class="title">ALFAISAL</span>
                <span class="custom-border top"></span>
                <span class="custom-border left"></span>
                <span class="custom-border bottom"></span>
                <span class="custom-border right"></span>
              </div>
            </b-col>
            <b-col cols="12" lg="7" :order-lg="(index + 1)%2 != 0 ? '0' : ''">
              <div class="service-info-container" data-aos="fade-left" data-aos-duration="5000">
                <h4 class="mb-3">{{service.name}}</h4>
                <div class="mb-3 pr-lg-5" v-html="service.description"></div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .our-services {
    .services-container {
      .service-image-container {
        width: 300px;
        height: 400px;
        position: relative;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        p {
          pointer-events: none;
          transition: all 0.4s linear;
          position: absolute;
          top: 40px;
          width: calc(100% + 14px);
          color: #fff;
          background: $primary;
          padding: 12px 1rem;
          padding-right: calc(1rem + 15px);
          text-align: center;
          font-size: 22px;
          font-weight: 600;
          line-height: 2;
          left: -15px;
          z-index: 2;
          opacity: 1;
          visibility: visible;
          &:after {
            content: "";
            position: absolute;
            bottom: -15px;
            width: 15px;
            height: 15px;
            left: 0;
            background: #621200;
            clip-path: polygon(0 0, 100% 0, 0 100%);
          }
        }
        .title {
          position: absolute;
          bottom: -20px;
          right: 0;
          color: $primary;
          letter-spacing: 16px;
          font-size: 12px;
        }
        .custom-border {
          display: block;
          background: $primary;
          position: absolute;
          &.top {
            top: -12px;
            right: -12px;
            width: 136px;
            height: 3px;
          }
          &.left {
            top: -12px;
            right: -12px;
            height: 240px;
            width: 3px;
          }
          &.bottom {
            bottom: -12px;
            left: -12px;
            width: 136px;
            height: 3px;
          }
          &.right {
            bottom: -12px;
            left: -12px;
            height: 240px;
            width: 3px;
          }
        }
      }
      .service-info-container {
        h4 {
          display: inline-block;
          padding-right: 60px;
          color: $primary;
          position: relative;
          font-weight: 600;
          &:after {
            content: "";
            position: absolute;
            bottom: -12px;
            left: 0;
            height: 3px;
            width: 100%;
            background: $secondaryPrimary;
          }
        }
        p {
          line-height: 1.7
        }
      }
    }
    @media screen and (min-width: 992px) {
      .services-container-item-row:nth-of-type(even) {
        .service-info-container {
            padding-left: 6rem;
        }
      }
    }
  }
  [dir="ltr"] {
    .our-services {
    .services-container {
      .service-image-container {
        p {
          &:after {
            clip-path: polygon(0 0, 100% 0, 100% 100%);
          }
        }
        .title {
          right: -12px;
        }
      }
    }
  }
  }
</style>
<script>
  import mainSectionVideo from "./components/main-section";
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import spinningStages from "./components/spinning-stages";
  import { mapGetters, mapActions } from 'vuex';
  import { handleLangChange } from "@/libs/event-bus";
  export default {
    components: {
      mainSectionVideo,
      spinningStages,
    },
    computed: {
      ...mapGetters(["services","stagesTitle"])
    },
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.services"),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: "description",
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    created() {
      this.getServiceForWebsite()
      handleLangChange(this.getServiceForWebsite)
    },
    setup() {
      const { t } = useI18nUtils();
      return { t }
    },
    methods: {
      ...mapActions(["getServiceForWebsite"])
    },
    beforeDestroy() {
        this.$store.dispatch("app/setLoadingWebsite", true);
    }
  };
</script>
