<template>
    <section class="spinning-video">
        <b-col>
          <div class="flex-grow-1 video-viewer text-center">
            <div class="py-5 px-lg-5 spinning-video-container justify-content-center">
              <div class="h-100 container-video" v-html="videoUrl"></div>
            </div>
          </div>
        </b-col>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(["videoUrl"])
  },
  mounted() {
    let x = setInterval(() => {
      if(document.querySelector('.video-viewer iframe')) {
        document.querySelector('.video-viewer .container-video').style.width = document.querySelector('.video-viewer iframe').width + 'px'
        document.querySelector('.video-viewer iframe').style.maxWidth = document.querySelector('.video-viewer iframe').width + 'px'
        clearInterval(x)
      }
    }, 1000)
    addEventListener('scroll', () => {
      if(document.querySelector('.video-viewer iframe')) {
        document.querySelector('.video-viewer .container-video').style.width = document.querySelector('.video-viewer iframe').width + 'px'
        document.querySelector('.video-viewer iframe').style.maxWidth = document.querySelector('.video-viewer iframe').width + 'px'
      }
    })
  }
}
</script>
<style lang="scss">
  @import "src/assets/scss/variables/variables.scss";
  .spinning-video {
    .spinning-video-container {
      display: flex;
      .spinning-video-container-title {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        .title-border {
          flex-grow: 1;
          background: $primary;
        }
        span {
          padding: 2px 0;
        }
      }
    }
  }
  .video-viewer {
    iframe {
      width: 100%;
    }
  }
</style>